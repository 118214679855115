<template>
  <b-row class="match-height m-0">
    <div class="container-img-ai">

      <!-- Options controls left -->
      <div class="slider-card-left active-slider" ref="slider-left">
        <sider-left-sections 
          :category_selected="category_selected" 
          :credits="credits" 
          @show_modal_update_suscription="openModalMembership"
          :all_plans="all_plans"
        ></sider-left-sections>
      </div>
      <b-sidebar class="sidebar-left-sections" v-model="open_sidebar_left" shadow>
        <sider-left-sections 
          :category_selected="category_selected" 
          :credits="credits" 
          @show_modal_update_suscription="openModalMembership"
          :all_plans="all_plans"
        ></sider-left-sections>
      </b-sidebar>
      <!-- ----------------- -->
    
      <div class="card-content-center active-content-left" ref="content-center">
        <!-- Options layout grid, export -->

        <!-- ------------------- -->
        
        <div class="container-search">
          <!-- Container results -->
  
          <!-- Container generate images  -->
          <container-grid
            :credits_avaible="credits"
            @slide_window="slideWindow"
            @update_credits="updateCredits"
            v-if="category_selected.section === 'image'"
          ></container-grid>
          <!-- -------------------------- -->

          <!-- Container history -->
          <history-grid 
            @slide_window="slideWindow"
            @edit_history="editHistory"
            @back_history="editHistory"
            v-if="category_selected.section === 'histories'"
          ></history-grid>
          <!-- -------------------->

          <!-- Container comminity -->
          <community-grid 
            @slide_window="slideWindow"
            :category_selected="category_selected"
            v-if="category_selected.section === 'community'"
          ></community-grid>
          <!-- -------------------- -->

          <!-- Container Chat -->
          <chat
            :credits_avaible="credits"
            :category_selected="category_selected"
            @slide_window="slideWindow"
            @update_credits="updateCredits"
            v-if="category_selected.section === 'chat'"
          ></chat>

          <!-- Container Text -->
          <text-container
            :credits_avaible="credits"
            :category_selected="category_selected"
            @slide_window="slideWindow"
            @update_credits="updateCredits"
            @back_history="editHistory"
            v-if="category_selected.section === 'text'"
          ></text-container>
          <!-- -------------------- -->

           <!-- Container Video -->
           <video-grid
            :credits_avaible="credits"
            @slide_window="slideWindow"
            @update_credits="updateCredits"
            @show_modal_update_suscription="openModalMembership"
            v-if="category_selected.section === 'video'"
          ></video-grid>
          <!-- -------------------- -->
        </div>
      </div>
    </div>
    <modal-membership-group
      :open_modal_plans_group="open_modal_plans_group" 
      :key="update_modal"
      :month_dad="true"
      :texts="data_modal_membership"
    />
  </b-row>
</template>

<script>
import {
  BRow,
  VBToggle,
  BSidebar,
} from "bootstrap-vue";
import { getSummaryCredits } from '@/services/ia'
import service from "@/services/proposals";
import { separatebycomma } from '@/libs/utils/formats'

export default {
  name: "brandmeAI",
  components: {
    BRow,
    BSidebar,
    videoGrid: () => import('@/views/components/brandme-ai/videoGrid.vue'),
    historyGrid: () => import('@/views/components/brandme-ai/historyGrid.vue'),
    
    communityGrid: () => import('@/views/components/brandme-ai/communityGrid.vue'),
    chat: () => import('@/views/components/brandme-ai/chat.vue'),
    containerGrid: () => import('@/views/components/brandme-ai/containerGrid.vue'),
    siderLeftSections: () => import('@/views/components/brandme-ai/siderbars/siderLeftSections.vue'),
    ModalMembershipGroup: () => import('@/views/components/modal/ModalMembershipGroup.vue'),
    textContainer: () => import('@/views/components/brandme-ai/textContainer.vue'),
  },
  directives: {
    'b-toggle': VBToggle
  },
  data() {
    return {
      separatebycomma,
      category_selected: {
        section: '',
        category: ''
      },
      open_sidebar_left: false,
      credits: 0,
      all_plans: [],
      open_modal_plans_group: false,
      update_modal: false,
      data_modal_membership: {},
    };
  },

  created() {
    this.$store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: 'hidden' });
    this.category_selected = this.$route.params;
    this.getSummaryCredits();
    this.getPlansMembers();
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: 'static' });
  },
  methods: {
    getPlansMembers() {
      service.getPlan().then((response) => {
        this.all_plans = response.results;
      });
    },
    openModalMembership(data) {
      this.data_modal_membership = (data && Object.keys(data).length > 0) ? data : {};
      if (this.credits <= 200) {
        this.open_modal_plans_group = true; 
        this.update_modal = !this.update_modal;
      }
    },
    editHistory(data = 'image', category = null) {
      if (data === 'image') {
        this.category_selected.section = 'image';
        this.category_selected.category = 'txt-to-image';
      } else if (data === 'video') {
        this.category_selected.section = 'video';
        this.category_selected.category = 'talking-portrait';
      } else if (data === 'text') {
        this.category_selected.section = 'text';
        this.category_selected.category = category ? category : 'social-post';
      } else if (data === 'history') {
        this.category_selected.section = 'histories';
        this.category_selected.category = 'text';
      }
    },
    slideWindow() {
      this.open_sidebar_left = !this.open_sidebar_left;
      const slideSide = this.$refs['slider-left'];
      const contentCenter = this.$refs['content-center'];
      slideSide.classList.toggle("active-slider")
      contentCenter.classList.toggle("active-content-left")
    },
    updateCredits(credits_used) {
      this.credits -= credits_used;
    },
    getSummaryCredits() {
      getSummaryCredits().then((response) => {
        this.credits = response.credits_available;
      })
    }
  },
};
</script>
<style scoped lang="scss">
.sidebar-left-sections {

  display: none;
}
.white-space-pre-wrap {
  white-space: pre-wrap;
}

.match-height {
  height: 78vh;
}
.container-img-ai{
  display: flex;
  flex-direction: row; 
  height: 100%;
  width: 100%;

  @media(max-width: 500px) {
    height: 78vh;
  }
}

.slider-card-left {
  width: 290px;
  flex-shrink: 0;
  background-color: white;
  height: 100%;
  transform: translateX(-340px);
  margin-right: -290px;
  transition: all 700ms ease-in-out;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-top-left-radius: 0.428rem;
  border-bottom-left-radius: 0.428rem;
}
.active-slider {
  width: 290px;
  transform: translateX(0px);
  margin-right: 0px;
  flex-shrink: 0;
}

.card-content-center{
  width: 100%;
  height: 100%;
  transition: all 700ms ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
}
.active-content-left {
  /* width: 100%; */
}
.p-1rem{
  padding: 1rem;
}
.width-right-area{
  width: 24%;
  height: 100%;
}
.size-icons-left {
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.5rem;
}
.size-img-icon {
  width: 20px;
  height: 20px;
}

.pos-relative{
  position: relative;
}
.image-size-active-slide {
  width: 33%;
  padding: 0.5em;
}
.w-50{
  width: 50%;
  padding: 0.3em 0.5em;
}

.p-left{
  padding: 10px 12px;
}

.container-search {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.options-button-panel{
  display: flex;
  flex-direction: row;
  align-items: center;
}
max-width-disabled
.body-button-center{
  position: relative;
  background-color: #e9ecef;
  padding: .25rem .25rem;
  border-radius: .375rem;
}

.toggle-hover:hover{
  cursor: pointer;
}

@media (max-width: 1199px) {
  .match-height {
    height: 89vh;
  }

  .width-right-area{
    display: none;
  }
  .container-img-ai{ 
    width: 100%;
  }
  .background-input-botton{
    width: 100%;
    height: auto;
  }
}

@media (max-width: 800px) {

  .slider-card-left {
    display: none;
  }
  .sidebar-left-sections {
    display: block;
  }
}
@media (max-width: 770px) {

  .image-size-active-slide{
    width: 50%;
  }
  .max-width-disabled{
    display: none;
  }
  .size-drop-responsive{
    display: flex;
  }
  .active-slider{
    width: 50%;
}
}
@media (max-width: 513px) {
  .image-size-active-slide{
    width: 100%;
  }
}
</style>

<style lang="scss">
.sidebar-left-sections .b-sidebar {
  background-color: white !important;
}
.open-sidebar-search > .b-sidebar-right {
  animation: sidebar 500ms;
}
@keyframes sidebar {
  0% {
    transform: translateX(500px)
  }
  100% {
    transform: translateX(0px)
  }
}
.container-aspect-ratio {
  position: relative;
}
</style>